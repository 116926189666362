<template>
    <AppLayout>
        <RequireLogin>
            <v-snackbar v-model="snackbarRememberDevice" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Personal device remembered</span>
                <v-btn text class="blue white--text" @click="snackbarRememberDevice = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarRememberDeviceError" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Error remembering personal device</span>
                <v-btn text class="red white--text" @click="snackbarRememberDeviceError = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarIdentityNameUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Identity updated</span>
                <v-btn text class="blue white--text" @click="snackbarIdentityNameUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarIdentityNameUpdatedError" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Identity failed to update</span>
                <v-btn text class="red white--text" @click="snackbarIdentityNameUpdatedError = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarLockTimeUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Lock time updated</span>
                <v-btn text class="blue white--text" @click="snackbarLockTimeUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
             <v-snackbar v-model="snackbarLockTimeUpdatedError" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Error updating lock time</span>
                <v-btn text class="red white--text" @click="snackbarLockTimeUpdatedError = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarIdleTimeUpdated" :timeout="2000" top color="blue" class="mt-0 pt-0">
                <span>Idle time updated</span>
                <v-btn text class="blue white--text" @click="snackbarIdleTimeUpdated = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <v-snackbar v-model="snackbarIdleTimeUpdatedError" :timeout="2000" top color="red" class="mt-0 pt-0">
                <span>Error updating idle time</span>
                <v-btn text class="red white--text" @click="snackbarIdleTimeUpdatedError = false"><font-awesome-icon icon="check" fixed-width/></v-btn>
            </v-snackbar>
            <Tutorial next="/dashboard">
                <p class="text-subtitle-1 font-weight-light text-center"><b>Preferences</b> is where you can change settings that are not account specific.</p>
            </Tutorial>
            <v-row justify="center" class="py-5">
                <h1 class="text-h6 font-weight-light">Preferences</h1>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Remember Me</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center">
                            <v-checkbox
                                v-model="isTrusted"
                                @change="editIsTrusted"
                                color="blue">
                                <template #label>
                                    <div>This is a personal device</div>
                                </template>
                            </v-checkbox>
                        </v-row>
                        <v-row justify="center">
                            <v-col cols="12">
                                <div class="text-body-1 font-weight-light text-center mx-5" v-show="isTrusted">
                                    <p>Login via push notification is enabled. If you share this browser with other people, the 'login' screen may display your name.</p>
                                </div>
                                <div class="text-body-1 font-weight-light text-center mx-5" v-show="!isTrusted">
                                    <p>Login via push notification is disabled.</p>
                                </div>
                            </v-col>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
             <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="pa-5">
                        <v-card-text class="text-h6 text-center pa-0 pb-3">Name</v-card-text>
                        <v-divider class="mx-5 mb-5"></v-divider>
                        <v-row justify="center" class="pb-3">
                            <v-col cols="12">
                                <div class="text-body-1 font-weight-light text-center mx-5">
                                    <p>We will use this name to address you on our website and when we communicate by email, telephone, or letters.</p>
                                    <p>For example, "Sparky" or "Doctor Who"</p>
                                </div>
                            </v-col>
                        </v-row>
                        <v-row justify="center" class="mx-5" style="display: block">
                            <v-form v-model="editNameForm" ref="editNameFormRef" @submit="validateEditName" onSubmit="return false;" @keyup.enter.native="validateEditName">
                                <v-text-field
                                    v-model=editableName
                                    label="Your nickname, first name, or title"
                                    :rules="nameRules"
                                    outlined
                                ></v-text-field>
                            </v-form>
                        </v-row>
                        <v-row justify="center">
                            <v-btn elevation="6" class="blue white--text" @click="editName" :disabled="!isIdentityNameComplete">
                                <font-awesome-icon icon="check" fixed-width/>
                                Save
                            </v-btn>
                        </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="px-10 pb-5">
                        <v-card-text class="text-h6 text-center">Lock</v-card-text>
                        <v-divider class="mx-5 mb-10"></v-divider>
                        <v-select :items="sessionExpiresChoices" v-model="sessionIdleExpiresMillis" @change="editSessionIdleExpiresMillis" label="Automatically lock account after..."></v-select>
                        <v-select :items="sessionExpiresChoices" v-model="sessionLockExpiresMillis" @change="editSessionLockExpiresMillis" label="Automatically forget locked account after..."></v-select>
                    </v-card>
                </v-col>
            </v-row>
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <v-card elevation="6" class="px-10 pb-10">
                            <v-card-text class="text-h6 text-center">Access Recovery</v-card-text>
                            <v-divider class="mx-5 mb-5"></v-divider>
                            <v-row justify="center" class="py-5 px-5">
                                <p class="text-body-1 font-weight-light text-center">Manage access recovery settings</p>
                            </v-row>
                            <v-row justify="center">
                                <v-btn color="blue" outlined elevation="4" @click="goToAccessRecovery()">Access Recovery</v-btn>
                            </v-row>
                    </v-card>
                </v-col>
            </v-row>
            <!--
            <v-row justify="center" class="py-5">
                <v-col cols="12" sm="10" md="8" lg="6" xl="4" class="pa-0">
                    <SessionList/>
                </v-col>
            </v-row>
            -->
        </RequireLogin>
    </AppLayout>
</template>

<script>
import { mapState /* , mapGetters */ } from 'vuex';
import RequireLogin from '@/components/RequireLogin.vue';
import Tutorial from '@/components/Tutorial.vue';
import AppLayout from '@/components/AppLayout.vue';
// import SessionList from '@/components/SessionList.vue';

export default {
    components: {
        RequireLogin,
        Tutorial,
        AppLayout,
        // SessionList,
    },
    data() {
        return {
            snackbarRememberDevice: false,
            snackbarRememberDeviceError: false,
            snackbarIdentityNameUpdated: false,
            snackbarIdentityNameUpdatedError: false,
            snackbarLockTimeUpdated: false,
            snackbarLockTimeUpdatedError: false,
            snackbarIdleTimeUpdated: false,
            snackbarIdleTimeUpdatedError: false,
            editNameForm: false,
            isCurrent: false,
            nameRules: [
                (v) => (typeof v === 'string' && v.length >= 1) || 'Must not be empty',
            ],
            editableName: null,
            defaultSessionIdleExpiresMillis: null,
            defaultSessionLockExpiresMillis: null,
            isTrusted: false,
            sessionExpiresChoices: [
                { text: 'Default', value: 0 },
                { text: '2 min', value: 2 * 60 * 1000 },
                { text: '5 min', value: 5 * 60 * 1000 },
                { text: '10 min', value: 10 * 60 * 1000 },
                { text: '15 min', value: 15 * 60 * 1000 },
                { text: '30 min', value: 30 * 60 * 1000 },
                { text: '45 min', value: 45 * 60 * 1000 },
                { text: '1 hour', value: 1 * 60 * 60 * 1000 },
                { text: '2 hours', value: 2 * 60 * 60 * 1000 },
                { text: '4 hours', value: 4 * 60 * 60 * 1000 },
                { text: '8 hours', value: 8 * 60 * 60 * 1000 },
                { text: '12 hours', value: 12 * 60 * 60 * 1000 },
                { text: '1 day', value: 1 * 24 * 60 * 60 * 1000 },
                { text: '7 days', value: 7 * 24 * 60 * 60 * 1000 },
            ],
        };
    },
    computed: {
        ...mapState({
            isReady: (state) => state.isReady,
            user: (state) => state.user,
            session: (state) => state.session,
        }),
        isIdentityNameComplete() {
            return this.user
            && this.editableName
            && this.editableName !== this.user.name;
        },
        name: {
            get() {
                if (this.user.name) {
                    return this.user.name;
                }
                return '';
            },
            set(value) {
                this.user.name = value;
            },
        },
        sessionIdleExpiresMillis: {
            get() {
                if (this.user.sessionIdleExpiresMillis) {
                    return this.user.sessionIdleExpiresMillis;
                }
                return '';
            },
            set(value) {
                this.user.sessionIdleExpiresMillis = value;
            },
        },
        sessionLockExpiresMillis: {
            get() {
                if (this.user.sessionLockExpiresMillis) {
                    return this.user.sessionLockExpiresMillis;
                }
                return '';
            },
            set(value) {
                this.user.sessionLockExpiresMillis = value;
            },
        },
    },
    watch: {
        name(val) {
            this.editableName = val;
        },
        isReady(value, oldValue) {
            console.log('account.vue: isReady changed from %o to %o', oldValue, value);
            // only call init again if ready changed from false to true after mounted()
            if (value && !oldValue) {
                this.init();
            }
        },
    },
    methods: {
        async init() {
            this.isTrusted = this.session.isTrusted;
            this.editableName = this.name;
            this.sessionIdleExpiresMillis = this.user.sessionIdleExpiresMillis;
            this.sessionLockExpiresMillis = this.user.sessionLockExpiresMillis;
        },
        async editIsTrusted() {
            const newValue = !this.session.isTrusted;
            const result = await this.$store.dispatch('editSession', { isTrusted: newValue });
            if (result) {
                this.isTrusted = newValue;
                this.snackbarRememberDevice = true;
            } else {
                this.snackbarRememberDeviceError = true;
            }
        },
        validateEditName() {
            if (this.$refs.editNameFormRef.validate()) {
                this.editName();
            }
        },
        async editName() {
            const result = await this.$store.dispatch('editUser', { name: this.editableName });
            if (result) {
                this.name = this.editableName;
                this.snackbarIdentityNameUpdated = true;
            } else {
                this.snackbarIdentityNameUpdatedError = true;
            }
        },
        async editSessionIdleExpiresMillis() {
            const result = await this.$store.dispatch('editUser', { sessionIdleExpiresMillis: this.sessionIdleExpiresMillis });
            if (result) {
                this.snackbarIdleTimeUpdated = true;
            } else {
                this.snackbarIdleTimeUpdatedError = true;
            }
            console.log('sessionIdleExpiresMillis: %o', this.sessionIdleExpiresMillis);
        },
        async editSessionLockExpiresMillis() {
            const result = await this.$store.dispatch('editUser', { sessionLockExpiresMillis: this.sessionLockExpiresMillis });
            if (result) {
                this.snackbarLockTimeUpdated = true;
            } else {
                this.snackbarLockTimeUpdatedError = true;
            }
            console.log('sessionLockExpiresMillis: %o', this.sessionLockExpiresMillis);
        },
        goToAccessRecovery() {
            this.$router.push('/access-recovery');
        },
    },
    mounted() {
        if (this.isReady) {
            this.init();
        }
    },
};
</script>
